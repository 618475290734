/* eslint-disable react/jsx-curly-newline */
import { makeStyles, Typography } from '@material-ui/core';
import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import { UIContext } from '../../../modules/general/ui';
import { ShopContext } from '../../../modules/takeout/shop';
import { TakeoutHolidayContext, takeoutHolidayOperations } from '../../../modules/takeout/takeoutHoliday';
// TODO: CSS in JSに置き換え
import '../../../styles/Calendar.css';
import { isDayIndexCorrect, toUTCDatetimeStrFromDate } from '../../../utils/dateUtil';
import { ClassName } from '../../general/props/classname';
import { HolidaySubmitButton } from '../parts/settingHolidaySubmitButton';

type Props = ClassName;

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2rem',
  },
  calendarStyle: {
    width: '100%',
  },
  selectedDay: {
    color: '#fff',
    backgroundColor: '#faa583',
  },
  button: {
    width: '30%',
    margin: '2rem 0',
    padding: '0.6rem 2rem',
  },
}));

export const Holiday: FC<Props> = () => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const shopStore = useContext(ShopContext);
  const holidayStore = useContext(TakeoutHolidayContext);

  const [selectedDates, setSelectedDate] = useState<string[]>([]);

  useEffect(() => {
    if (holidayStore.state.holidays.length !== 0) {
      setSelectedDate(holidayStore.state.holidays.map((holiday) => holiday.date));
    }
  }, [holidayStore.state.holidays]);

  const availableDays = useMemo(() => {
    return shopStore.state.shop.takeoutReceptionTimezones.flatMap(
      (takeoutReceptionTimezone) => takeoutReceptionTimezone.dayOfWeek.id
    );
  }, [shopStore.state.shop.takeoutReceptionTimezones]);

  const isDisableDate = (date: Date) => {
    if (!isDayIndexCorrect(shopStore.state.shop.takeoutReceptionTimezones)) throw new Error();
    if (date && !availableDays.includes(date.getDay())) return true;
    return false;
  };

  const removeSelectDate = (date: Date) => {
    const newDates = [...selectedDates];
    const filterNewDates = newDates.filter((newDate) => newDate !== toUTCDatetimeStrFromDate(date));
    setSelectedDate(filterNewDates);
  };

  const onClickDay = (date: Date) =>
    selectedDates.some((selectedDate) => selectedDate === toUTCDatetimeStrFromDate(date))
      ? removeSelectDate(date)
      : setSelectedDate([...selectedDates, toUTCDatetimeStrFromDate(date)]);

  const onSubmit = async () => {
    await takeoutHolidayOperations.updateTakeoutHoliday(uiStore.dispatch, holidayStore.dispatch, {
      shopId: shopStore.state.shop.id,
      dates: selectedDates,
    });
  };

  return (
    <div className={classes.wrapper}>
      <Calendar
        className={classes.calendarStyle}
        locale="ja-JP"
        calendarType="US"
        defaultView="month"
        minDetail="year"
        showNeighboringMonth={false}
        minDate={startOfMonth(subMonths(Date.now(), 0))}
        maxDate={endOfMonth(addMonths(Date.now(), 18))}
        onClickDay={onClickDay}
        tileClassName={({ date, view }) => {
          return view === 'month' &&
            selectedDates.some((selectedDate) => selectedDate === toUTCDatetimeStrFromDate(date))
            ? classes.selectedDay
            : null;
        }}
        tileContent={({ date, view }) =>
          view === 'month' && selectedDates.some((selectedDate) => selectedDate === toUTCDatetimeStrFromDate(date)) ? (
            <Typography>休業日</Typography>
          ) : null
        }
        tileDisabled={({ date, view }) => view === 'month' && isDisableDate(date)}
      />
      <HolidaySubmitButton
        className={classes.button}
        onSubmit={onSubmit}
        color="positive"
        texts={['休業日を確定する']}
      />
    </div>
  );
};
