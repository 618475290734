import { makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
  title: {
    fontSize: '2rem',
    '@media (max-width: 896px)': {
      fontSize: '1.2rem',
    },
  },
  description: {
    fontSize: '1rem',
    '@media (max-width: 896px)': {
      fontSize: '0.8rem',
    },
  },
  boldText: {
    fontWeight: 700,
  },
}));

export const ChooseHeaderString: FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <Typography className={`${classes.title} ${classes.boldText}`}>休業日設定</Typography>
      <Typography className={`${classes.description}`}>下のカレンダーで休日を設定してください</Typography>
    </div>
  );
};
