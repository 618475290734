import { DTOTakeoutHoliday } from 'models';
import { Reducer } from 'react';
import { TypeOfActions } from './actions';
import * as types from './types';

export interface State {
  holidays: DTOTakeoutHoliday[];
}

export const initialState: State = {
  holidays: [],
};

export const reducer: Reducer<State, TypeOfActions> = (state, action) => {
  switch (action.type) {
    case types.GET_TAKEOUT_HOLIDAYS_SUCCEED:
      return {
        ...state,
        holidays: action.payload.holidays,
      };
    default:
      return state;
  }
};
