import { makeStyles, ThemeProvider } from '@material-ui/core';
import React, { FC } from 'react';
import { HolidayLayout } from '../../../components/takeout/layouts/holidayLayout';
import { takeoutTheme } from '../../../components/takeout/theme/takeoutTheme';
import { BufferContextProvider } from '../../../modules/general/buffer';
import { SocketContextProvider } from '../../../modules/general/socket';
import { TaxContextProvider } from '../../../modules/general/tax';
import { UIContextProvider } from '../../../modules/general/ui';
import { ShopContextProvider } from '../../../modules/takeout/shop';
import { TakeoutHolidayContextProvider } from '../../../modules/takeout/takeoutHoliday';
import { TakeoutOrderContextProvider } from '../../../modules/takeout/takeoutOrder';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));

const TakeoutCalendarPage: FC = () => {
  const classes = useStyles();

  return (
    <UIContextProvider>
      <TaxContextProvider>
        <TakeoutHolidayContextProvider>
          <SocketContextProvider>
            <BufferContextProvider>
              <TakeoutOrderContextProvider>
                <ShopContextProvider>
                  <ThemeProvider theme={takeoutTheme}>
                    <div className={classes.wrapper}>
                      <HolidayLayout />
                    </div>
                  </ThemeProvider>
                </ShopContextProvider>
              </TakeoutOrderContextProvider>
            </BufferContextProvider>
          </SocketContextProvider>
        </TakeoutHolidayContextProvider>
      </TaxContextProvider>
    </UIContextProvider>
  );
};

export default TakeoutCalendarPage;
