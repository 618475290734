import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { FunctionsMenuButton } from '../../general/parts/functionsMenuButton';
import { ClassName } from '../../general/props/classname';
import { ChooseHeaderString } from '../parts/chooseHeaderString';

type Props = ClassName;

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '95%',
    display: 'flex',
    marginTop: '2rem',
  },
  header: {
    marginLeft: '5%',
  },
  toggleMenuWrapper: {
    width: '5%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export const HolidayHeader: FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <ChooseHeaderString className={classes.header} />
      <FunctionsMenuButton className={classes.toggleMenuWrapper} />
    </div>
  );
};
