import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';
import { Holiday } from '../units/holiday';
import { HolidayHeader } from '../units/holidayHeader';

type Props = ClassName;

const useStyles = makeStyles(() => ({
  wrapper: {
    // iconSideMenuのwidthを減算したwidthを設定
    width: 'calc(100% - 14rem)',
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 2.5rem)',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
  },
}));

export const HolidayMain: FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <HolidayHeader />
      <Holiday />
    </div>
  );
};
