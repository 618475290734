import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  color: 'positive' | 'negative' | 'default';
  texts: string[];
  disabled?: boolean;
  autoFocus?: boolean;
  onSubmit?: () => void;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0.8rem 1rem',
    borderRadius: '3rem',
    boxShadow: '0px 1px 5px 1px rgba(0,0,0,0.15)',
    fontSize: '1rem',
  },
  multipleLinesButton: {
    padding: '0.2rem 1rem !important',
  },
  positive: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    },
    '&:active': {
      background: theme.palette.primary.main,
    },
  },
  negative: {
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.main,
    },
    '&:active': {
      background: theme.palette.secondary.main,
    },
  },
  default: {
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.main,
    },
    '&:active': {
      background: theme.palette.secondary.main,
    },
  },
  contained: {
    '&$disabled': {
      background: '#d4d4d4',
    },
  },
  disabled: {},
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    padding: '0 0 0 0.5rem',
  },
  arrowRight: {
    order: 2,
    padding: '0 0.3rem',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: '1em',
    fontWeight: 700,
    padding: '0 0.5rem',
    order: 1,
  },
  multipleLines: {
    fontSize: '0.8rem',
  },
}));

export const HolidaySubmitButton: FC<Props> = ({ className, color, texts, disabled, autoFocus, onSubmit }) => {
  const classes = useStyles();

  return (
    <Button
      className={`${classes.wrapper} ${classes[color]}  ${className} ${
        texts.length > 1 && classes.multipleLinesButton
      }`}
      classes={{ contained: classes.contained, disabled: classes.disabled }}
      variant="contained"
      disabled={disabled}
      onClick={onSubmit}
      autoFocus={autoFocus}
    >
      <div className={classes.content}>
        <div className={classes.textWrapper}>
          {texts.map((text) => (
            <Typography
              key={`takeout-button-${text}`}
              className={`${classes.text} ${texts.length > 1 && classes.multipleLines}`}
            >
              {text}
            </Typography>
          ))}
        </div>
      </div>
    </Button>
  );
};
