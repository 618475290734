import { fetchman } from '../../../utils/fetchman';
import { uiActions } from '../../general/ui';
import { TypeOfActions as TypeOfUIActions } from '../../general/ui/actions';
import { actions, TypeOfActions } from './actions';

/**
 * GET /api/takeout/holiday/:shopId
 */
export const getTakeoutHoliday = async (
  uiDispatch: React.Dispatch<TypeOfUIActions>,
  dispatch: React.Dispatch<TypeOfActions>,
  props: { shopId: number }
) => {
  try {
    uiDispatch(uiActions.fetchProgress());

    const data = await fetchman(`/api/takeout/holidays/${props.shopId}`, { method: 'GET' });

    dispatch(actions.getTakeoutHolidaysSucceed(data.result));
  } catch (error) {
    uiDispatch(uiActions.fetchFailed('テイクアウト休業日情報の取得に失敗しました'));
  }
};

/**
 * PUT /api/takeout/update
 */
export const updateTakeoutHoliday = async (
  uiDispatch: React.Dispatch<TypeOfUIActions>,
  dispatch: React.Dispatch<TypeOfActions>,
  props: { shopId: number; dates: string[] }
) => {
  try {
    uiDispatch(uiActions.fetchProgress());

    await fetchman(`/api/takeout/holidays/update`, { method: 'PUT', body: props });

    uiDispatch(uiActions.fetchSucceed('テイクアウト休業日情報を変更しました'));
  } catch (error) {
    uiDispatch(uiActions.fetchFailed('テイクアウト休業日情報の変更に失敗しました'));
  }
};
