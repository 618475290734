import { DTOTakeoutHoliday } from 'models';
import * as types from './types';

/**
 * テイクアウト休業日設定成功時アクション
 */
const getTakeoutHolidaysSucceed = (holidays: DTOTakeoutHoliday[]) => ({
  type: types.GET_TAKEOUT_HOLIDAYS_SUCCEED,
  payload: {
    holidays,
  },
});

export const actions = {
  getTakeoutHolidaysSucceed,
};
export type TypeOfActions = ReturnType<typeof getTakeoutHolidaysSucceed>;
